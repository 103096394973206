<template>
  <div id="result-scores-info">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <template v-if="loading">
        <loading>{{ progress }}</loading>
      </template>
      <template v-else>
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <form @submit.prevent="passes(sendResultEmail)">
            <div class="columns is-multiline">
              <div class="column is-12">
                <b-table :data="studentsInClass" :checked-rows.sync="checkedStudents" checkable hoverable>
                  <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
                      studentsInClass.indexOf(props.row) +
                      1
                  }}</b-table-column>

                  <b-table-column label="RegId" v-slot="props">{{
                      props.row.regId.toUpperCase()
                  }}</b-table-column>

                  <b-table-column label="Name" v-slot="props">{{
                      props.row.name
                  }}</b-table-column>

                  <template slot="bottom-left">
                    <SdInput type="text" rules="required" label="Total checked" v-model="checkedStudents.length"
                      placeholder="Total checked" readonly />
                  </template>
                </b-table>
              </div>
              <div class="column is-12">
                <button type="submit" class="button btn-120 is-primary is-capitalized is-pulled-right">
                  Send Email
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </template>

    </template>
  </div>
</template>

<script>
import axios from 'axios'
import gql from 'graphql-tag'
import { fetchTerm, fetchUser, fetchAppUrl } from '@/assets/js/app_info.js'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'result-scores-info',
  data() {
    return {
      pageTitle: 'School Class Results',
      schoolId: null,
      loading: false,
      studentsInClass: [],
      checkedStudents: [],
      path: null,
      asyncJob: null,
      skipQuery: true,
      progress: 'Fetching PDF...',
    }
  },
  apollo: {
    asyncJob: {
      query: gql`
        query asyncJobQuery($id: Int!) {
          asyncJob(id: $id) {
            jobId
            status
            progressCount
            result
          }
        }
      `,
      variables() {
        return { id: parseInt(this.async_job_id) }
      },
      skip() {
        return this.skipQuery
      },
      pollInterval: 500,
    },
    schoolClass: {
      query: gql`
        query schoolClassQuery($id: ID!) {
          schoolClass(id: $id) {
            id
            name
          }
        }
      `,
      variables() {
        return { id: parseInt(this.$route.params.id) }
      },
    },
  },
  watch: {
    asyncJob(data) {
      if (data.status === 'Done') {
        this.loading = false
        this.skipQuery = true
      } else {
        this.loading = true
        this.progress = data.status
      }
    },
  },
  methods: {
    sendResultEmail() {
      fetchAppUrl().then((path) => {
        this.email(path)
      })
    },
    email(path) {
      axios
        .get(`${path}/bulk_result_email`, {
          params: {
            student_ids: this.checkedStudents.map((student) => student.id),
          },
        })
        .then((response) => {
          this.skipQuery = false
          this.async_job_id = response.data.async_job_id
        })
        .catch((error) => {
          // console.log(error.message);
        })
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.schoolClassId = parseInt(this.$route.params.id)
    fetchUser().then((user) => {
      const menus = [
        {
          name: 'School Class Info',
          route: `/school/${this.schoolId}/school_class_info/${this.$route.params.id}`,
        },
      ]
      this.$store.commit('setSubMenus', menus)
    })
    fetchTerm().then((term) => {
      this.termId = parseInt(term.id)
      this.termTitle = term.session_title
    })
    this.$apollo.addSmartQuery('studentsInClass', {
      query: gql`
        query studentsInClass($schoolClassId: Int!) {
          studentsInClass(schoolClassId: $schoolClassId) {
            id
            regId
            name
          }
        }
      `,
      variables: {
        schoolClassId: parseInt(this.schoolClassId),
      },
    })
  },
}
</script>

<style lang="scss" scoped>
iframe {
  display: block;
  border: none;
  height: 100vh;
  width: 100%;
}
</style>
